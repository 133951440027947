export const UsersTypes = [
    {
        text: 'Super Admin',
        value: 'superAdmin'
    },
    {
        text: 'User',
        value: 'user'
    },
    {
        text: 'Dispatcher',
        value: 'dispatcher'
    }
    ]

export const UserRolesInDepartment = [
    {
        text: 'Department Admin',
        value: 'departmentAdmin'
    },
    {
        text: 'Subscriber',
        value: 'subscriber'
    }
]

export const EditMapsPermissionOptions = [
    {
        text: 'Enable',
        value: true
    },
    {
        text: 'Disable',
        value: false
    }
]

export const DispatcherRolesInDepartment = [
    {
        text: 'Dispatcher',
        value: 'dispatcher'
    }
]
export const UserRolesMap = {
    departmentAdmin: 'Department Admin',
    subscriber: 'Subscriber',
    dispatcher: 'Dispatcher'
}

export const StatusTypes = [
    { text: 'Activated', value: true },
    { text: 'Deactivated', value: false }
]

export const DepartmentTypes = [
    {
        text: 'Administration',
        value: 'administration'
    },
    {
        text: 'EMS',
        value: 'ems'
    },
    {
        text: 'Fire',
        value: 'fire'
    },
    {
        text: 'Law',
        value: 'law'
    },
    {
        text: 'OEM',
        value: 'oem'
    },
    {
        text: 'Police',
        value: 'police'
    },
    {
        text: 'School',
        value: 'school'
    },
    {
        text: 'Other',
        value: 'other'
    },
]

export const ToneTypes = [
    {
        text: 'A Tone',
        value: 'a'
    },
    {
        text: 'B Tone',
        value: 'b'
    }
]

export const Timezones = [
    {
        text: 'EST',
        value: 'America/New_York'
    },
    {
        text: 'CST',
        value: 'America/Chicago'
    },
    {
        text: 'MST',
        value: 'America/Denver'
    },
    {
        text: 'PST',
        value: 'America/Los_Angeles'
    },
]