// React
import React, { createRef } from 'react'
import { Prompt } from "react-router-dom"
// Material UI
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Typography, CircularProgress,
    IconButton, Button, Tabs, Tab, AppBar, Toolbar
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
// Components
import ActionConfirmation from '../../general/ActionConfirmation'
import CallTypesTab from './tabs/callTypes'
import DepartmentUsersTab from './tabs/DepartmentUsersTab'
import DepartmentFeaturesPermission from './tabs/DepartmentFeaturesPermissions'
import FirePitchTab from './tabs/firePitch'
import FacebookTab from './tabs/facebook'
import InfoTab from './tabs/info'
import ApiTab from './tabs/api'
import RadioTab from './tabs/radio'
// Utilities
import moment from 'moment'
import { DispatchDurationOptions } from '../../../utilities/info'
import { ToneTypes } from '../../../utilities/Options'


const tabs = ['info', 'permissions', 'calltypes', 'firepitch', 'users', 'facebook', 'api', 'radio']

const initialState = {
    isDepartmentUnsaved: false,
    currentTab: 'info',
    confirmDeletion: false,
    confirmClone: false,
    cloning: false,
    confirmRenewDepartmentApiKey: false,
    departmentApiKey: null,
    generateDepartmentApiKeyLoading: false,
    renewDepartmentApiKeyLoading: false
}

class ViewOrCreateDepartmentModal extends React.Component {

    state = initialState
    focusRef = createRef(null)

    componentDidMount() {
        window.addEventListener("beforeunload", this.beforeunload)
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { isDepartmentUnsaved } = this.state
        if (!isDepartmentUnsaved) {

            if (prevProps.department !== this.props.department) {
                // department plan is handled instantly, no need to set unsaved flag
                this.setState({ isDepartmentUnsaved: true })
            }
        }
        if (prevState.currentTab !== this.state.currentTab) {
            this.focusRef?.current?.focus?.()
            this.focusRef?.current?.focusVisible?.()
        }
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.beforeunload)
    }

    beforeunload = (e) => {
        const { update } = this.props
        const { isDepartmentUnsaved } = this.state
        if (isDepartmentUnsaved || !update) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    handleTabChange = (e, value, direction) => {
        const { handleErrors } = this.props
        if (handleErrors(this.state.currentTab)) {
            const index = tabs.indexOf(value)

            if (index === -1) {
                return null
            }

            let newIndex
            if (direction === "prev") {
                newIndex = (index - 1 + tabs.length) % tabs.length
                this.setState({ currentTab: tabs[newIndex] })
            } else if (direction === "next") {
                newIndex = (index + 1) % tabs.length
                this.setState({ currentTab: tabs[newIndex] })
            } else {
                this.setState({ currentTab: value })
            }
        }
    }

    handleClose = () => {
        const { update } = this.props
        const { isDepartmentUnsaved } = this.state
        if (isDepartmentUnsaved || !update) {
            let shouldCloseModal = window.confirm(
                "Changes you made may not be saved. Are you sure you want to leave this page?"
            )
            if (!shouldCloseModal) return
            else this.setState({ ...initialState })
        }
        this.props.handleClose()
    }

    updateDepartment = async () => {
        const response = await this.props.updateDepartment()
        const { isDepartmentUnsaved } = this.state
        if (response && isDepartmentUnsaved)
            this.setState({ isDepartmentUnsaved: false })
    }

    openConfirmDeletion = () => {
        this.setState({ confirmDeletion: true })
    }

    closeConfirmDeletion = () => {
        this.setState({ confirmDeletion: false })
    }

    openConfirmClone = () => {
        this.setState({ confirmClone: true });

    }

    closeConfirmClone = () => {
        this.setState({ confirmClone: false });
    }

    render() {
        const {
            handleInputChange, handleFloatInputChange, handleAddCallType, handleDeleteCallType, createDepartment,
            department, errors, loaders, open, addToneField, deleteToneField, update, deleteDepartment,
            authenticatedUser, accessToken, handleColorChange, departmentOptions, nonDefaultCallTypes,
            defaultCallTypes, radioChannels, playRadio, handleCloneClick, socket, handleAddFacebookPage,
            handleDeleteFacebookPage
        } = this.props

        const { isDepartmentUnsaved, currentTab, confirmDeletion, confirmClone } = this.state


        return (
            <>
                <Prompt
                    when={isDepartmentUnsaved || !update}
                    message={() =>
                        "Changes you made may not be saved. Are you sure you want to leave this page?"
                    }
                />
                <Dialog
                    aria-labelledby='customized-dialog-title'
                    onClose={!loaders.createNewDepartment ? this.handleClose : undefined}
                    open={open}
                    PaperProps={{ style: { minWidth: '80vw', minHeight: '90vh' } }}
                >
                    <AppBar
                        color='inherit'
                        style={{ width: '80vw', left: '10vw' }}
                    >
                        <Toolbar>
                            <Typography
                                variant='h6'
                                style={{ fontWeight: 'bold' }}
                            >
                                {department.name}
                            </Typography>
                            <Tabs
                                value={currentTab}
                                onChange={this.handleTabChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="view or create departments tabs"
                                style={{ flex: 1, marginBottom: 0 }}
                            >
                                <Tab label="Info" value='info' style={{ opacity: 1, width: 70, marginLeft: 70 }} />
                                <Tab label="Permissions" value='permissions' style={{ opacity: 1, width: 70 }} />
                                <Tab label="CallTypes" value='calltypes' style={{ opacity: 1, width: 70 }} />
                                <Tab label="FirePITCH" value='firepitch' style={{ opacity: 1, width: 70 }} />
                                {update && <Tab label="Users" value='users' style={{ opacity: 1, width: 70 }} />}
                                {update && <Tab label="Facebook" value='facebook' style={{ opacity: 1, width: 70 }} />}
                                {update && authenticatedUser.type === 'superAdmin' && <Tab label="API" value="api" style={{ opacity: 1, width: 70 }} />}
                                {update && department.featuresPermission.audioDispatches === true && department.radioChannelObject?._id &&
                                    <Tab label="Radio" value="radio" style={{ opacity: 1, width: 70 }} />
                                }
                            </Tabs>
                            <IconButton
                                style={{ marginLeft: 'auto' }}
                                aria-label='close'
                                onClick={this.handleClose}
                                disabled={loaders.createNewDepartment}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {['info', 'permissions', 'calltypes', 'firepitch'].includes(currentTab) &&
                        // show title only on info, callTypes & audioTones tabs
                        <DialogTitle
                            style={{
                                marginTop: 32,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'baseline'
                            }}
                            disableTypography
                        >
                            {update ?
                                <>
                                    <Typography
                                        variant='h6'
                                    >
                                        Update Department
                                    </Typography>
                                    <Typography
                                        variant='caption'
                                    >
                                        Created at {moment(department.createdAt).format('MM/DD/YYYY')}
                                    </Typography>
                                </>
                                :
                                <Typography
                                    variant='h6'
                                >
                                    Create New Department
                                </Typography>
                            }

                        </DialogTitle>
                    }
                    <DialogContent
                        dividers
                    >
                        { // info
                            currentTab === 'info' &&
                            <InfoTab
                                key={department._id}
                                department={department}
                                departmentOptions={departmentOptions}
                                loaders={loaders}
                                errors={errors}
                                handleInputChange={handleInputChange}
                                handleColorChange={handleColorChange}
                                update={update}
                            />
                        }
                        { // permissions
                            currentTab === 'permissions' && authenticatedUser.type === 'superAdmin' &&
                            <DepartmentFeaturesPermission
                                department={department}
                                errors={errors}
                                handleInputChange={handleInputChange}
                                loaders={loaders}
                            />
                        }
                        { // calltypes
                            currentTab === 'calltypes' &&
                            <CallTypesTab
                                ref={this.focusRef}
                                department={department}
                                departmentOptions={departmentOptions}
                                nonDefaultCallTypes={nonDefaultCallTypes}
                                defaultCallTypes={defaultCallTypes}
                                loaders={loaders}
                                errors={errors}
                                handleInputChange={handleInputChange}
                                handleAddCallType={handleAddCallType}
                                handleDeleteCallType={handleDeleteCallType}
                            />
                        }
                        {/* audio tones */}
                        {currentTab === "firepitch" &&
                            <FirePitchTab
                                ref={this.focusRef}
                                department={department}
                                toneTypes={ToneTypes}
                                dispatchDurationOptions={DispatchDurationOptions}
                                radioChannels={radioChannels}
                                loaders={loaders}
                                errors={errors}
                                addToneField={addToneField}
                                deleteToneField={deleteToneField}
                                handleFloatInputChange={handleFloatInputChange}
                                handleInputChange={handleInputChange}
                            />

                        }
                        {/* users */}
                        {
                            currentTab === 'users' &&
                            <DepartmentUsersTab
                                department={department}
                                departments={departmentOptions}
                                authenticatedUser={authenticatedUser}
                                accessToken={accessToken}
                            />
                        }
                        {/* facebook */}
                        {currentTab === "facebook" &&
                            <FacebookTab
                                department={department}
                                loaders={loaders}
                                handleInputChange={handleInputChange}
                                handleAddFacebookPage={handleAddFacebookPage}
                                handleDeleteFacebookPage={handleDeleteFacebookPage}
                            />
                        }
                        { // api
                            currentTab === "api" &&
                            <ApiTab
                                authenticatedUser={authenticatedUser}
                                department={department}
                                update={update}
                                socket={socket}
                            />
                        }
                        {currentTab === "radio" && // radio
                            <RadioTab
                                department={department}
                                radioChannels={radioChannels}
                                playRadio={playRadio}
                            />
                        }
                    </DialogContent>

                    <DialogActions
                        style={{ display: 'flex', flex: 1, maxHeight: 50 }}
                    >
                        {update &&
                            <>
                                {currentTab === 'info' &&
                                    <>
                                        <Button
                                            onClick={this.openConfirmDeletion}
                                            variant='contained'
                                            color='secondary'
                                            style={{ marginRight: 8, backgroundColor: '#ff0000' }}
                                            disabled={loaders.deleteDepartmen || loaders.createNewDepartment}
                                        >
                                            {loaders.deleteDepartment &&
                                                <CircularProgress
                                                    size={18}
                                                    style={{ marginRight: 8 }}
                                                />
                                            }
                                            Delete
                                        </Button>
                                        {authenticatedUser.type === 'superAdmin' && (
                                            <Button
                                                onClick={this.openConfirmClone}
                                                variant='contained'
                                                color='primary'
                                                style={{ backgroundColor: '#1976d2' }}
                                                disabled={loaders.handleCloneClic || loaders.createNewDepartment}
                                            >
                                                {loaders.handleCloneClick && <CircularProgress size={18} style={{ marginRight: 8 }} />}
                                                Clone
                                            </Button>
                                        )}

                                    </>
                                }
                                {currentTab !== 'users' && currentTab !== "api" &&
                                    <Button
                                        onClick={this.updateDepartment}
                                        color='secondary'
                                        variant='contained'
                                        style={{ marginLeft: 'auto' }}
                                        disabled={loaders.deleteDepartmen || loaders.createNewDepartment}
                                    >
                                        {loaders.createNewDepartment &&
                                            <CircularProgress
                                                size={18}
                                                style={{ marginRight: 8 }}
                                            />
                                        }
                                        Update
                                    </Button>
                                }
                            </>
                        }
                        {!update &&
                            <>
                                <Button
                                    aria-label='back'
                                    onClick={() => this.handleTabChange(null, currentTab, 'prev')}
                                    color='primary'
                                    variant='outlined'
                                    style={{ marginRight: 'auto' }}
                                    disabled={currentTab === 'info'}
                                >
                                    Back
                                </Button>
                                {currentTab !== "firepitch" ?
                                    <Button
                                        aria-label='next'
                                        onClick={() => this.handleTabChange(null, currentTab, 'next')}
                                        color='secondary'
                                        variant='contained'
                                        style={{ marginLeft: 'auto' }}

                                    >
                                        Next
                                    </Button>
                                    :
                                    <Button
                                        onClick={createDepartment}
                                        color='secondary'
                                        variant='contained'
                                        style={{ marginLeft: 'auto' }}
                                        disabled={loaders.deleteDepartmen || loaders.createNewDepartment}
                                    >
                                        {loaders.createNewDepartment &&
                                            <CircularProgress
                                                size={18}
                                                style={{ marginRight: 8 }}
                                            />
                                        }
                                        Create
                                    </Button>
                                }
                            </>
                        }

                    </DialogActions>
                </Dialog>
                <ActionConfirmation
                    open={confirmDeletion}
                    onClose={this.closeConfirmDeletion}
                    title='Are you sure you want to delete this Department?'
                    onConfirmAction={deleteDepartment}
                />

                <ActionConfirmation
                    open={confirmClone}
                    onClose={this.closeConfirmClone}
                    title='Are you sure you want to clone this Department?'
                    onConfirmAction={() => handleCloneClick(department._id)}
                />
            </>
        )
    }
}

export default ViewOrCreateDepartmentModal;
